import React from 'react'

import { Button, fonts } from '@lumoslabs/lumosity-storybook'
import type { NextPage } from 'next'
import Head from 'next/head'
import router from 'next/router'
import styled from 'styled-components'

import BasicHeader from '~/components/BasicHeader'
import Footer from '~/components/Footer'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

const { Heading1, Subheading1 } = fonts

const NotFoundPage: NextPage = () => {
  const t = useTranslationForNamespace('404')

  return (
    <>
      <Head>
        <title>{t('title')}</title>
      </Head>
      <BasicHeader />
      <Container>
        <Heading1 as='h1'>{t('title')}</Heading1>
        <Subheading1 as='h2'>{t('message')}</Subheading1>
        <Button onClick={() => router.push('/')}>{t('common:buttons.returnHome')}</Button>
      </Container>
      <Footer />
    </>
  )
}
export default NotFoundPage

const Container = styled.div`
  margin: 50px;
`
